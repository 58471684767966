import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa'; // Icon for hamburger menu
import { IoClose } from 'react-icons/io5'; // Icon for close button

const Sidebar = ({ onMenuItemClick }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <div className="flex h-screen">
      <div
        className={`${
          isOpen ? 'w-64' : 'w-16'
        } bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white transition-width duration-300 ease-in-out flex flex-col shadow-lg`}
      >
        <div className="flex items-center justify-between h-16 border-b border-pink-400 px-4">
          <h1 className={`${isOpen ? 'block' : 'hidden'} text-xl font-bold tracking-wide`}>
            TaskHive
          </h1>
          <button onClick={toggleSidebar} className="focus:outline-none md:hidden">
            {isOpen ? (
              <IoClose className="text-2xl" />
            ) : (
              <FaBars className="text-2xl" />
            )}
          </button>
        </div>
        <nav className="flex-grow p-4 overflow-y-auto">
          <ul className="space-y-2">
            <li
              className="flex items-center p-3 rounded-lg hover:bg-purple-600 cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => onMenuItemClick('sales')}
            >
              <span className="flex-grow">Sales Report</span>
            </li>
            <li
              className="flex items-center p-3 rounded-lg hover:bg-purple-600 cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => onMenuItemClick('customers')}
            >
              <span className="flex-grow">New Customers Report</span>
            </li>
            <li
              className="flex items-center p-3 rounded-lg hover:bg-purple-600 cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => onMenuItemClick('repeatedCustomers')}
            >
              <span className="flex-grow">Customers Growth Report</span>
            </li>
            <li
              className="flex items-center p-3 rounded-lg hover:bg-purple-600 cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => onMenuItemClick('geoDistribution')}
            >
              <span className="flex-grow">Customers Geographical Distribution</span>
            </li>
            <li
              className="flex items-center p-3 rounded-lg hover:bg-purple-600 cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => onMenuItemClick('cohortChart')}
            >
              <span className="flex-grow">Customers Cohort Value Report</span>
            </li>
          </ul>
        </nav>
      </div>
      <div className="flex-grow bg-gray-100 p-4 md:p-8 overflow-auto">
        {/* Main content goes here */}
      </div>
    </div>
  );
};

export default Sidebar;
