import React, { useState } from 'react';
import Sidebar from './Sidebar';
import SalesChart from './SalesChart';
import NewCustomersChart from './NewCustomersChart';
import RepeatCustomersChart from './RepeatedCustomersChart';
import LocationWiseCustomer from './LocationWiseCustomer';
import CohortValueChart from './CohortValueChart';

const Layout = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('sales');

  const renderDashboard = () => {
    switch (selectedMenuItem) {
      case 'sales':
        return <SalesChart />;
      case 'customers':
        return <NewCustomersChart />;
      case 'repeatedCustomers':
        return <RepeatCustomersChart />;
      case 'geoDistribution':
        return <LocationWiseCustomer />;
      case 'cohortChart':
        return <CohortValueChart />;
      default:
        return <SalesChart />;
    }
  };

  return (
    <div className="flex bg-gray-50 min-h-screen">
      <Sidebar onMenuItemClick={setSelectedMenuItem} />
      <div className="flex-grow p-6">
        {renderDashboard()}
      </div>
    </div>
  );
};

export default Layout;
