
import './App.css';
import SalesChart from './components/SalesChart';
import Layout from './components/Layout';

function App() {
  return (
    <div>
    <Layout />
  </div>
  );
}

export default App;
